import COPY from '@c/assets/badges/user/copy.svg'
import LOVED from '@c/assets/badges/user/loved.svg'
import TOP_CONTRIBUTOR from '@c/assets/badges/user/top_contributor.svg'
import User from '@c/models/User.ts'

export enum WORKSPACE_MEMBER_STATUS {
  INVITED = 'INVITED',
  ACCESS_REQUESTED = 'ACCESS_REQUESTED',
  ACTIVE = 'ACTIVE',
  IMPORTED = 'IMPORTED',
  INACTIVE = 'INACTIVE'
}

export function isGhostUser(workspaceMember: WorkspaceMember) {
  return [
    WORKSPACE_MEMBER_STATUS.INACTIVE,
    WORKSPACE_MEMBER_STATUS.IMPORTED,
    WORKSPACE_MEMBER_STATUS.INVITED
  ].includes(workspaceMember.status)
}

export default interface WorkspaceMember {
  uuid: string
  user: User
  job_title: string | null
  status: WORKSPACE_MEMBER_STATUS
  workspace?: string
}

export interface WorkspaceMemberMinimal {
  uuid: string
  user: User
}

export enum Badges {
  COPY = 'COPY',
  LOVED = 'LOVED',
  TOP_CONTRIBUTOR = 'TOP_CONTRIBUTOR'
}

export const BadgesToImage: Record<string, string> = {
  [Badges.COPY]: COPY,
  [Badges.LOVED]: LOVED,
  [Badges.TOP_CONTRIBUTOR]: TOP_CONTRIBUTOR
}
