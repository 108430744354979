












import BrowseWorkspacesPage from '@c/features/onboarding/pages/BrowseWorkspacesPage.vue'
import Workspace from '@c/models/Workspace'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default Vue.extend({
  name: 'WorkspaceListWrapper',
  components: { BrowseWorkspacesPage },

  props: {
    canReturn: {
      type: Boolean,
      default: undefined
    }
  },

  computed: {
    ...mapState({
      currentUser: (state) =>
        state.users.currentUser || state.users.currentWorkspaceMember,
      workspaces: (state) => state.workspaces.workspaces
    }),

    joinedWorkspaces() {
      return Object.values(this.workspaces)
    }
  },

  methods: {
    ...mapActions(['getJoinableWorkspaces', 'joinWorkspace', 'logout']),

    async doLogout() {
      await this.logout()
      this.$router.push('login')
    },

    next(workspace?: Workspace) {
      this.$router.push({
        name: 'home',
        params: {
          ...this.$route.params,
          workspace_id:
            workspace?.uuid || Object.values(this.workspaces)[0].uuid
        }
      })
    },

    async acceptInvitation(workspace: Workspace) {
      try {
        await this.joinWorkspace({ workspace })
        this.$toast.success(
          'Invitation accepted',
          `You are now a member of ${workspace.name}.`
        )
        this.next()
      } catch (e) {
        this.$console.debug(e)
        this.$toast.error(e, 'joining this workspace')
      }
    }
  }
})
