var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BrowseWorkspacesPage", {
    attrs: {
      "can-return": _vm.joinedWorkspaces.length > 0,
      "current-user": _vm.currentUser,
      "joined-workspaces": _vm.joinedWorkspaces
    },
    on: {
      join: _vm.acceptInvitation,
      next: _vm.next,
      newWorkspace: function($event) {
        return _vm.$router.push({ name: "workspace-new" })
      },
      logout: _vm.doLogout
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }