var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { filter: "drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.04))" }
    },
    [
      _c(
        "div",
        {
          staticClass: "border semi-hard border subtle-primary thick element",
          staticStyle: { background: "white" }
        },
        [
          _c("div", { staticClass: "pb-2 pt-4 pl-4 pr-4" }, [
            _c("div", { staticClass: "u-columns is-vcentered mb-2" }, [
              _c(
                "div",
                { staticClass: "u-column is-narrow" },
                [
                  _c("WorkspaceLogo", {
                    staticClass: "mr-3",
                    staticStyle: { "background-color": "black" },
                    attrs: {
                      "logo-size": "medium",
                      "light-text": "",
                      "workspace-icon": _vm.workspace.image,
                      "workspace-title": _vm.workspace.name
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "u-column" }, [
                _c("p", { staticClass: "header bold sm" }, [
                  _vm._v(_vm._s(_vm.workspace.name))
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "\n          columns\n          is-mobile is-multiline\n          no-margin\n          space-between\n          full-height\n        ",
                staticStyle: { "flex-direction": "column" }
              },
              [
                _c("div", { staticClass: "column is-narrow no-padding" }, [
                  _c(
                    "p",
                    {
                      staticClass: "body xxs two-lines",
                      staticStyle: { height: "36px" }
                    },
                    [_vm._v(" " + _vm._s(_vm.workspace.description) + " ")]
                  )
                ]),
                _c("div", { staticClass: "column is-narrow no-padding" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "columns is-mobile is-vcentered no-margin space-between"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "column is-narrow no-padding body xs" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.workspace.member_count + " members") +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "column is-narrow no-padding" },
                        [
                          _vm._t(
                            "action",
                            function() {
                              return [
                                _vm.workspaceMember &&
                                _vm.workspaceMember.status ===
                                  _vm.workspaceMemberStatus.ACTIVE
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "body body-text",
                                        attrs: { "icon-right": "check-circle" }
                                      },
                                      [_vm._v(" Member ")]
                                    )
                                  : _vm.workspace.is_open ||
                                    (_vm.workspaceMember &&
                                      _vm.workspaceMember.status ===
                                        _vm.workspaceMemberStatus.INVITED)
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "body body-text",
                                        attrs: { "icon-right": "check-circle" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "joinWorkspace",
                                              _vm.workspace
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Join workspace ")]
                                    )
                                  : _vm._e()
                              ]
                            },
                            { workspace: _vm.workspace }
                          )
                        ],
                        2
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }