






































import Vue from 'vue'

const sizes = {
  small: {
    'font-size': '20px',
    'margin-top': '4px'
  },
  xs: {
    'font-size': '14px',
    'margin-top': '-4px'
  },
  medium: {
    'font-size': '26px',
    'margin-top': '-2px'
  },
  large: {
    'font-size': '36px',
    'margin-top': '-6px'
  },
  xxl: {
    'font-size': '64px',
    'margin-top': '-12px'
  }
}

export default Vue.extend({
  name: 'WorkspaceLogo',
  props: {
    workspaceIcon: {
      type: String,
      default: undefined
    },
    workspaceTitle: {
      type: String,
      default: undefined
    },
    logoSize: {
      type: String,
      default: 'xs'
    },
    dark: {
      type: Boolean,
      default: false
    },
    darkText: {
      type: Boolean,
      default: false
    },
    lightText: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sizes() {
      return sizes
    }
  }
})
