var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "u-icon is-square square rounded borderless",
        class:
          ((_obj = {}),
          (_obj[_vm.logoSize] = true),
          (_obj.transparent = !_vm.dark),
          _obj)
      },
      _vm.$listeners
    ),
    [
      _vm.workspaceIcon
        ? _c("img", {
            staticClass:
              "u-icon is-square square rounded transparent workspace-logo",
            class: ((_obj$1 = {}), (_obj$1[_vm.logoSize] = true), _obj$1),
            attrs: { src: _vm.workspaceIcon }
          })
        : _c(
            "div",
            {
              staticClass: "u-icon is-square square rounded workspace-logo",
              class:
                ((_obj$2 = {}),
                (_obj$2[_vm.logoSize] = true),
                (_obj$2["dark-background"] = _vm.dark),
                (_obj$2.transparent = !_vm.dark),
                _obj$2)
            },
            [
              _c("div", { staticClass: "u-columns align-center center" }, [
                _c(
                  "div",
                  { staticClass: "u-column is-narrow" },
                  [
                    _vm._t("icon", function() {
                      return [
                        _c(
                          "p",
                          {
                            staticClass: "body bold has-text-centered",
                            class: {
                              "has-text-white":
                                (_vm.dark && !_vm.darkText) || _vm.lightText
                            },
                            style: _vm.sizes[_vm.logoSize]
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$umodel.capitalize(
                                    (_vm.workspaceTitle || [""])[0]
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }