var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BareApplicationShell",
    _vm._g(
      {
        attrs: { "current-user": _vm.currentUser },
        scopedSlots: _vm._u(
          [
            _vm.canReturn
              ? {
                  key: "left",
                  fn: function() {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "home",
                              params: {
                                workspace_id: _vm.joinedWorkspaces[0].uuid
                              }
                            }
                          }
                        },
                        [
                          _c("BackButton", {
                            staticClass: "ml-3",
                            attrs: {
                              text: "Back to your workspaces",
                              "custom-text-class": "link blue bold xxs"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 80px)", "overflow-y": "hidden" }
        },
        [
          _c(
            "div",
            {
              staticClass: "u-columns",
              staticStyle: { "flex-direction": "column", height: "100%" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "\n          u-column\n          is-narrow\n          onboarding-message-container\n          pa-5\n          full-width\n        "
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "u-columns is-vcentered",
                      staticStyle: { "flex-direction": "column" }
                    },
                    [
                      _c("div", { staticClass: "u-column is-narrow" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "\n                header\n                lg\n                bold\n                has-text-centered\n                onboarding-message-container-text\n              "
                          },
                          [_vm._v(" Join your colleagues in these workspaces ")]
                        )
                      ]),
                      _c("div", { staticClass: "u-column is-narrow" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "\n                body\n                sm\n                has-text-centered\n                onboarding-message-container-text\n              ",
                            staticStyle: { "max-width": "500px" }
                          },
                          [
                            _vm._v(
                              " Workspaces are communities of people where knowledge is shared. "
                            )
                          ]
                        )
                      ]),
                      _vm.isUman
                        ? _c(
                            "div",
                            { staticClass: "u-column is-narrow mt-4" },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        id: "new-workspace-link",
                                        type: "is-primary",
                                        expanded: "",
                                        "icon-left": "plus-circle"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("newWorkspace")
                                        }
                                      }
                                    },
                                    [_vm._v(" Create new workspace ")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "u-column",
                  staticStyle: { "overflow-y": "auto" }
                },
                [
                  _c("div", { staticClass: "workspace-containers" }, [
                    _c(
                      "div",
                      { staticClass: "ma-7" },
                      [
                        _vm.joinedWorkspaces && _vm.joinedWorkspaces.length > 0
                          ? _c(
                              "ContentWithHeader",
                              {
                                attrs: {
                                  title: "YOUR WORKSPACES",
                                  subtitle: "",
                                  type: "capped-title",
                                  "title-size": "xs",
                                  "subtitle-size": "xs"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "u-columns is-multiline is-vcentered"
                                  },
                                  _vm._l(_vm.joinedWorkspaces, function(
                                    workspace
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: workspace.uuid,
                                        staticClass:
                                          "\n                    u-column\n                    is-one-third-desktop is-half-tablet is-full-mobile\n                  "
                                      },
                                      [
                                        _c("WorkspaceCard", {
                                          staticClass: "ma-3",
                                          attrs: { workspace: workspace },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "action",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "home",
                                                            params: Object.assign(
                                                              {},
                                                              _vm.$route.params,
                                                              {
                                                                workspace_id:
                                                                  workspace.uuid
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "border borderless element",
                                                            attrs: {
                                                              size: "is-small",
                                                              type: "is-primary"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "body bold has-text-white ph-2"
                                                              },
                                                              [_vm._v("Go")]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }