













































































import WorkspaceMember, {
  WORKSPACE_MEMBER_STATUS
} from '@c/models/WorkspaceMember'
import WorkspaceLogo from '@c/shared/molecules/object-visualisations/workspace/WorkspaceLogo.vue'
import Vue, { PropType } from 'vue'
import Workspace from '@c/models/Workspace'

export default Vue.extend({
  name: 'WorkspaceCard',
  components: { WorkspaceLogo },
  props: {
    workspace: {
      type: Object as PropType<Workspace>,
      required: true
    },
    workspaceMember: {
      type: Object as PropType<WorkspaceMember | undefined>,
      default: undefined
    }
  },
  data() {
    return {
      workspaceMemberStatus: WORKSPACE_MEMBER_STATUS
    }
  }
})
