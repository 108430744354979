



























































































































import BareApplicationShell from '@c/features/app/pages/BareApplicationShell.vue'
import User from '@c/models/User'
import Workspace from '@c/models/Workspace'
import BackButton from '@c/shared/molecules/buttons/BackButton.vue'
import WorkspaceCard from '@c/shared/molecules/object-visualisations/workspace/WorkspaceCard.vue'
import ContentWithHeader from '@c/shared/molecules/structural/blocks/ContentWithHeader.vue'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

export default Vue.extend({
  name: 'BrowseWorkspacesPage',
  components: {
    BareApplicationShell,
    WorkspaceCard,
    ContentWithHeader,
    BackButton
  },
  props: {
    currentUser: {
      type: Object as () => User,
      default: undefined
    },
    canSkip: {
      type: Boolean,
      default: false
    },
    joinedWorkspaces: {
      type: Array as () => Workspace[],
      default: () => []
    },
    canReturn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isUman']),
    ...mapState({
      workspaces: state => state.workspaces.workspaces
    })
  }
})
